import React from "react";
import BackgroundImage from "gatsby-background-image";

const Masthead = (props) => {

    return (
        <>
            <section className="mb-12 md:mb-16">
                <BackgroundImage tag="div"
                                 className="relative bg-cover bg-center masthead"
                                 fluid={props.image}>
                    <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-white"></div>
                </BackgroundImage>

                <div className="bg-primary">
                    <h1 className="py-6 text-center text-white text-3xl leading-none container md:py-8 md:text-5xl">{props.title}</h1>
                </div>
            </section>
        </>
    )
};

export default Masthead;
