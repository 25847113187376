import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Masthead from "../components/masthead";

export default function ContentTemplate({ data }) {
    const { markdownRemark } = data;
    const { title, featured_image } = markdownRemark.frontmatter;
    const { html } = markdownRemark;

    return (
        <Layout>
            <SEO title={title}/>
            <Masthead title={title} image={featured_image.childImageSharp.fluid}/>

            <div className="pb-12 max-w-4xl text-center prose container md:pb-24" dangerouslySetInnerHTML={{ __html: html }}></div>
        </Layout>
    )
}

export const query = graphql`
    query($pathSlug: String!) {
        markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
            html
            frontmatter {
                title,
                featured_image {
                    childImageSharp {
                        fluid(maxWidth: 1400, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
